{
    "global_olympic_content_01": "时尚之都巴黎即将迎来2024年奥运会，这场世界级体育盛事将吸引全球目光。让我们一起在这个独特时刻为奥运会注入时尚动力! ",
    "global_olympic_content_02": "因此，Style3D正在组织一场以\"用Style3D重塑奥运时尚\"为主题的设计大赛，激发更多人对于3D时装设计的创作热情，为有才华的设计师提供了展示自我的更大平台。",
    "global_olympic_content_03": "我们在寻找什么？",
    "global_olympic_content_04": "征集对象",
    "global_olympic_content_05": "<span class=\"large\">设计师</span>（独立设计师/设计工作室）",
    "global_olympic_content_06": "<span class=\"large\">时装/设计学院的学生</span>",
    "global_olympic_content_07": "征集要求",
    "global_olympic_content_08": "为奥运会运动员或观众创作独特的服装设计",
    "global_olympic_content_09": "设计必须与奥运会主题或巴黎元素相关",
    "global_olympic_content_10": "所有作品必须使用Style3D产品创作，包括Style3D Studio、Style3D iWish等",
    "global_olympic_content_11": "参考概念",
    "global_olympic_content_12": "设计应体现奥运会的精神和价值观，包括但不限于世界和平、相互理解、持久友谊、全球团结、公平竞争、自我提升、可持续发展、文化多样性、奥运城市体验等。",
    "global_olympic_content_13": "如何申请参加?",
    "global_olympic_content_14": "作品必须在<span class=\"large\">2024</span>年<span class=\"large\">8</span>月<span class=\"large\">15</span>日截止日期前提交",
    "global_olympic_content_15": "请点击下方表单提交投稿作品，内容应包括：",
    "global_olympic_content_16": "服装设计草图（可选）",
    "global_olympic_content_17": "成衣渲染图/走秀视频",
    "global_olympic_content_18": "3D服装工程文件",
    "global_olympic_content_19": "简短说明（300字以内）用于阐述设计理念",
    "global_olympic_content_20": "如有任何疑问，请随时通过电子邮件<a href=\"mailto:marketing@Style3D.com\" style=\"text-decoration:underline\">marketing@Style3D.com</a>与我们联系。",
    "global_olympic_content_21": "征集细则",
    "global_olympic_content_22": "时间线",
    "global_olympic_content_23": "提交作品",
    "global_olympic_content_24": "评审和公众投票",
    "global_olympic_content_25": "公布获奖名单",
    "global_olympic_content_26": "5月25日 - 7月25日",
    "global_olympic_content_27": "7月25日 - 8月10日",
    "global_olympic_content_28": "8月15日",
    "global_olympic_content_29": "奖项设置",
    "global_olympic_content_30": "最佳设计奖<span class=\"large\">（1名）</span>",
    "global_olympic_content_31": "￥15000元奖金",
    "global_olympic_content_32": "Style3D 中国总部参观 / 知名时尚品牌实习机会",
    "global_olympic_content_33": "设计师个人/工作室独家采访",
    "global_olympic_content_33_01": "Style3D官方荣誉证书",
    "global_olympic_content_33_02": "1年免费 Style3D Studio 账号",
    "global_olympic_content_34": "最具创意奖<span class=\"large\">（2名）</span>",
    "global_olympic_content_35": "￥7500元奖金",
    "global_olympic_content_36": "Styleverse 参观培训",
    "global_olympic_content_37": "设计师个人/工作室独家采访",
    "global_olympic_content_37_01": "Style3D官方荣誉证书",
    "global_olympic_content_37_02": "1年免费 Style3D Studio 账号",
    "global_olympic_content_38": "人气奖<span class=\"large\">（最多3名）</span>",
    "global_olympic_content_39": "Styleverse 参观培训",
    "global_olympic_content_40": "Style3D官方荣誉证书",
    "global_olympic_content_41": "1年免费 Style3D Studio 账号",
    "global_olympic_content_42": "荣誉奖<span class=\"large\">（最多5名）</span>",
    "global_olympic_content_43": "Style3D官方荣誉证书",
    "global_olympic_content_43_01": "1年免费 Style3D Studio 账号",
    "global_olympic_content_44": "所有入选作品均有机会与 Style3D 合作，将3D虚拟服装转化为独一无二的实物样衣，并在Styleverse伦敦/巴黎/慕尼黑展厅陈列展示。",
    "global_olympic_content_45": "其他事项",
    "global_olympic_content_46": "参赛作品须为原创作品，如与已发表的作品相同或近似的不予评选；参赛作品不得侵犯他人知识产权和权利，如发生纠纷，由此产生的责任由参赛者承担。",
    "global_olympic_content_47": "参赛作品的知识产权归参赛者所有，提交作品即代表授权Style3D对所有参赛作品享有推介、展示、出版及其他形式的推广、宣传等权利。",
    "global_olympic_content_48": "Style3D对本次活动保留最终解释权。",
    "global_olympic_content_49": "立即投稿",
    "global_olympic_content_50": "征集投稿作品：5月25日-8月15日",
    "global_olympic_content_51": "专家评审和公众投票：8月16日-8月29日",
    "global_olympic_content_52": "公布获奖名单：8月30日",
    "global_olympic_email_cn": "如有任何疑问，请随时通过电子邮件<a style=\"font-size: 20px;font-family: HarmonyOS Sans SC;color: #e7e7e7;\"  href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>与我们联系。",
    "global_olympic_email_en": "如有任何疑问，请随时通过电子邮件<a style=\"font-size: 18px;font-family: Montserrat;color: #e7e7e7;color: #e7e7e7;\"  href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>与我们联系。",
    "global_olympic_email_mobile_cn": "如有任何疑问，请随时通过电子邮件<a style=\"font-size: 12px;font-family: HarmonyOS Sans SC;color: #e7e7e7;\"  href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>与我们联系。",
    "global_olympic_email_mobile_en": "如有任何疑问，请随时通过电子邮件<a style=\"font-size: 10px;font-family: Montserrat;color: #e7e7e7;color: #e7e7e7;\"  href=\"mailto:marketing@Style3D.com\">marketing@Style3D.com</a>与我们联系。",
    "global_olympic_title8": "获奖企业名单",
    "global_olympic_text8a":"最佳成衣展示奖:",
    "global_olympic_list8a":"深圳市中纺实业有限公司",
    "global_olympic_list8b":"阜新福壹服饰有限公司",
    "global_olympic_list8c":"无锡谷德维斯纺织品有限公司",
    "global_olympic_list8d":"迪尚集团有限公司",
    "global_olympic_list8e":"宁波长隆进出口有限公司",
    "global_olympic_list8f":"江苏汇鸿国际集团中嘉发展有限公司",
    "global_olympic_list8g":"惠州桦岭进出口贸易有限公司",
    "global_olympic_list8h":"江苏苏美达轻纺国际贸易有限公司",
    "global_olympic_list8i":"衣针衣线（东莞）制衣有限公司",
    "global_olympic_list8j":"达利丝绸（浙江）有限公司",
    "global_olympic_list8k":"泉州海天材料科技股份有限公司",
    "global_olympic_list8l":"绍兴中庸纺织科技有限公司",
    "global_olympic_list8m":"上海飞尚纺织科技有限公司",
    "global_olympic_list8n":"常州恒利宝纳米新材料科技有限公司",
    "global_olympic_text8b":"最佳面料应用奖:",
    "global_olympic_text8c":"以上排名不分先后顺序<br>相关获奖权益会有工作人员进行对接发放，请耐心等待",
    "global_olympic_title9":"获奖个人名单",
    "global_olympic_list9a":"最佳设计奖：",
    "global_olympic_list9b":"Jacob Mougharbel",
    "global_olympic_list9c":"最具创意奖：",
    "global_olympic_list9d":"Kalina Panteleeva 、Olena Shcherbinina",
    "global_olympic_list9e":"人气奖：",
    "global_olympic_list9f":"杨丽媛、Martina Verde、Josephine Schäfer ",
    "global_olympic_list9g":"荣誉奖：",
    "global_olympic_list9h":"石艺、Marija Gordina、Kamol Meesri、Oleksandra Berezniuk、Tatiana Matiiasevich",
    "global_olympic_text7a_cn": "其他事项",
    "global_olympic_text7b1_cn": "参赛作品须为原创作品，如与已发表的作品相同或近似的不予评选；参赛作品不得侵犯他人知识产权和权利，如发生纠纷，由此产生的责任由参赛者承担。",
    "global_olympic_text7b2_cn": "参赛作品的知识产权归参赛者所有，提交作品即代表授权Style3D对所有参赛作品享有推介、展示、出版及其他形式的推广、宣传等权利。",
    "global_olympic_text7b3_cn": "本次活动与奥运赛事官方或赞助商无关，Style3D对本次活动保留最终解释权。",
    "global_olympic_title6_cn": "🎨 小红书分享有福利",
    "global_olympic_text6_cn": "小红书账号发布本次参赛投稿作品，发布时带上 <span style=\"color: #52ff8d;\">#Style3D挑战赛</span> 标签，并<span style=\"color: #52ff8d;\">@Style3D</span> 官方小红书账号，我们将挑选优秀创作者，送出Style3D定制礼品一份。",
    "global_olympic_text6_mobile": "<span style=\"opacity: 0.85;\">小红书账号发布本次参赛投稿作品，发布时带上</span> <span style=\"color: #52ff8d;\">#Style3D挑战赛</span><span style=\"opacity: 0.85;\"> 标签，并</span><span style=\"color: #52ff8d;\">@Style3D</span> <span style=\"opacity: 0.85;\">官方小红书账号，我们将挑选优秀创作者，送出Style3D定制礼品一份。</span>",
    "global_olympic_banner4_text1_cn": "如何申请参加?",
    "global_olympic_banner4_text2_cn": "作品必须在2024年8月15日截止日期前提交",
    "global_olympic_banner4_text3_cn": "请点击下方表单提交投稿作品，内容应包括：",
    "global_olympic_banner4_text4a_cn": "服装设计草图（可选)",
    "global_olympic_banner4_text4b_cn": "成衣渲染图/走秀视频",
    "global_olympic_banner4_text4c_cn": "3D服装工程文件",
    "global_olympic_banner4_text4d_cn": "简短说明（300字以内）用于阐述设计理念",
    "global_olympic_banner1_text1_cn": "时尚之都巴黎迎来2024年奥运会，这场世界级体育盛事吸引着全球目光。让我们一起在这个独特时刻为奥运会注入时尚动力! ",
    "global_olympic_banner1_text2_cn": "因此，Style3D组织了一场以&quot;用Style3D重塑奥运时尚&quot;为主题的设计大赛，激发更多人对于3D时装设计的创作热情，为有才华的设计师提供了展示自我的更大平台。",
    "global_olympic_header_text1_cn": "Recreate Olympic Fashion with Style3D",
    "global_olympic_header_text2_cn": "引领2024奥运新风尚！Style3D设计大赛等你来挑战",
    "global_company_synopsis": "公司简介",
    "global_company_vision": "公司愿景",
    "global_company_values": "价值观",
    "global_thanks_title_1": "感谢您的关注！",
    "global_thanks_title_2": "我们将会尽快与您联系。",
    "global_thanks_callback": "返回主页",
    "global_hello": "你好",
    "global_oops_page_not_found": "糟糕！页面没有找到",
    "global_back_to_home": "返回主页",
    "global_internal_error": "内部错误",
    "global_reload": "刷新页面",
    "global_question_button_1": "问题反馈",
    "global_home_question_form_key_1": "问题类型",
    "global_home_question_form_placeholder_1": "请选择您的问题类型",
    "global_home_question_form_key_2": "问题描述",
    "global_home_question_form_placeholder_2": "请输入您的具体问题描述",
    "global_home_question_form_key_3": "公司名称",
    "global_home_question_form_placeholder_3": "请输入您的公司名称",
    "global_home_question_form_key_4": "职位",
    "global_home_question_form_placeholder_4": "请输入您的职位",
    "global_home_question_form_key_5": "联系方式",
    "global_home_question_form_placeholder_5": "请输入您的联系方式",
    "global_home_question_form_key_6": "联系人",
    "global_home_question_form_placeholder_6": "请输入您的称呼",
    "global_link_button_0": "了解更多",
    "global_link_button_1": "申请试用",
    "global_link_button_2": "获取验证码",
    "global_link_button_3": "联系我们",
    "global_link_button_4": "下载申请表",
    "global_link_button_5": "更多新闻资讯",
    "global_link_button_6": "下载软件",
    "global_link_button_7": "联系我们",
    "global_link_button_8": "申请试用",
    "global_link_Individual": "个人用户",
    "global_link_Corporate": "企业用户",
    "global_link_button_9": "立即报名",
    "global_link_button_10": "立即投稿",
    "global_button_text_accept": "接受",
    "global_button_text_decline": "拒绝",
    "global_validate_message_0": "请输入",
    "global_validate_message_1": "请输入手机号！",
    "global_validate_message_2": "请输入正确的手机号！",
    "global_validate_message_3": "请输入电子邮箱！",
    "global_validate_message_4": "请输入合法的电子邮箱！",
    "global_apply_trial_submit_success": "提交成功",
    "global_apply_trial_submit_failed": "提交失败",
    "global_home_swipper_text": "打造数字引擎·驱动时尚未来",
    "global_home_swipper_text_mobile": "让未来时尚<br>所见即所得",
    "global_home_swipper_subtext": "利用生成式人工智能和实时模拟的前沿研究，Style3D为从事时尚、动画和游戏行业的<br>企业和个人提供数字时尚软件、硬件、云平台和全面服务。",
    "global_home_swipper_subtext_mobile": "利用生成式人工智能和实时模拟的前沿研究，Style3D为从事时尚、动画和游戏行业的企业和个人提供数字时尚软件、硬件、云平台和全面服务",
    "global_home_swipper_olympic_title": "Recreate Olympic Fashion with Style3D",
    "global_home_swipper_olympic_text": "引领2024奥运新风尚!<br>Style3D设计大赛等你来挑战",
    "global_home_swipper_olympic_date": "5.25-8.15",
    "global_home_swipper_secbanner_1": "CHIC&intertextile开展在即，Style3D展位精彩亮点抢先看！",
    "global_home_swipper_banner": "了解更多",
    "global_home_header_menu_0": "公司产品",
    "global_home_header_menu_00": "首页",
    "global_olympic2_titl1": "Recreate Olympic Fashion with Style3D",
    "global_olympic2_titl2": "引领2024奥运新风尚！Style3D设计大赛等你来挑战",
    "global_home_catalogue_category_0": "Style3D",
    "global_home_catalogue_item_name_0": "Studio",
    "global_home_catalogue_item_name_1": "Fabric",
    "global_home_catalogue_item_name_2": "Cloud",
    "global_home_catalogue_item_name_3": "Market",
    "global_home_section_title_0": "解决方案",
    "global_home_section_subtitle_0": "Solutions",
    "global_home_section_title_1": "与卓越同行，共创3D时尚未来",
    "global_home_section_subtitle_1": "Partners",
    "global_home_solutions_name_0": "品牌商解决方案",
    "global_home_procedure_content_title": "端到端的全链路高效流程",
    "global_home_procedure_content_subtitle": "End To End",
    "global_home_procedure_roadmap_title_0": "产品企划",
    "global_home_procedure_roadmap_subtitle_0": "Planning",
    "global_home_procedure_roadmap_title_1": "设计研发",
    "global_home_procedure_roadmap_subtitle_1": "Design",
    "global_home_procedure_roadmap_title_2": "产品生产",
    "global_home_procedure_roadmap_subtitle_2": "Production",
    "global_home_procedure_roadmap_title_3": "数字营销",
    "global_home_procedure_roadmap_subtitle_3": "Digital Marketing",
    "global_home_solutions_des_0": "无论大小品牌，均能找到适配的数字化解决方案",
    "global_home_solutions_name_1": "面料商解决方案",
    "global_home_solutions_des_1": "一站式实现“管理+协同+展销”面料数字化闭环",
    "global_home_solutions_name_2": "制造商解决方案",
    "global_home_solutions_des_2": "核心业务场景强关联，以数字化赋能企业竞争力",
    "global_home_solutions_name_3": "教育行业解决方案",
    "global_home_solutions_des_3": "从产业创新应用实践中来 到院校人才培养赋能中去",
    "global_home_user_voice_text_0": "通过与Style3D的合作，日播设计资源得以数据形式进行储存，并能检索及二次调用，设计价值得以最大化，且全链路的数字协同方式，也大幅缩短了研发周期。",
    "global_home_user_voice_text_1": "大家已经认识到数字化转型是一个必选题，不管是我们企业还是合作伙伴都在关注行业动向，安踏也在做数字化未来的蓝图，系统化支撑今后5至10年的业务发展，希望凌迪能成为未来3D数字化变革的引领者。",
    "global_home_user_voice_text_2": "迪尚有非常坚实的服装根基，且我们早早在数字化方面进行布局，对于数字时尚，我们还是非常有信心和Style3D一起往前走。",
    "global_home_user_voice_text_3": "Style3D让我们感受到了科技的力量，未来将进一步加深与Style3D的合作，打造从研发到生产到展销的全链路数字化。",

    "global_home_header_submenu_link_0_0_pc_des": "3D服装仿真建模软件",
    "global_home_header_submenu_link_0_1_pc_des": "数字面料创作软件",
    "global_home_header_submenu_link_0_2_pc_des":"跨终端3D服装实时搭配软件",
    "global_home_header_submenu_link_0_3_pc_des":"数字资产高效云协同平台",
    "global_home_header_submenu_link_0_4_pc_des":"3D服装建模和动画制作软件",
    "global_home_header_submenu_link_0_5_pc_des":"虚幻引擎数字服装模拟插件",
    "global_home_header_submenu_link_0_6_pc_des":"创意生成、智能增强出图",
    "global_home_header_submenu_link_0_7_pc_des":"针织数字化设计研发软件 (套件)",

    "global_home_header_submenu_link_0_0": "Style3D Studio",
    "global_home_header_submenu_link_0_0_des": "3D服装仿真建模软件",
    "global_home_header_submenu_link_0_0_des2": "融合布料仿真和AI技术，制作真实生动的3D服装，为您带来便捷、高效的数字创作体验",
    "global_home_header_submenu_link_0_0_des2_title": "融合布料仿真和AI技术，制作真实生动的3D服装，<br>为您带来便捷、高效的数字创作体验",
    "global_home_header_submenu_link_0_0_des3_title": "融合布料仿真和AI技术，制作真实生动的3D服装，<br>为您带来便捷、高效的数字创作体验",
    "global_home_header_submenu_link_0_1": "Style3D Fabric",
    "global_home_header_submenu_link_0_1_des": "配备面料扫描仪和测量仪的数字面料创作软件",
    "global_home_header_submenu_link_0_1_des_banner": "配备面料扫描仪和测量仪的数字面料创作软件",
    "global_home_header_submenu_link_0_1_des2": "数字面料的材质处理、物理属性编辑，为您呈现优质的数字面料创作体验",
    "global_home_header_submenu_link_0_1_des3": "数字面料的材质处理、物理属性编辑，为您呈现优质的数字面料创作体验",
    "global_home_header_submenu_link_0_1_des2_mobile": "数字面料的材质处理、物理属性编辑，为您呈现<br>优质的数字面料创作体验",
    "global_home_header_submenu_link_0_2": "Style3D Cloud",
    "global_home_header_submenu_link_0_2_des": "便于分享和管理的数字资产云协同平台",
    "global_home_header_submenu_link_0_2_des2": "数字资产管理、设计、展销、协作",
    "global_home_header_submenu_link_0_2_des3": "数字资产管理、设计、展销、协作",
    "global_home_header_submenu_link_0_2_des2_mobile": "数字资产管理、设计、展销、协作",
    "global_home_header_submenu_link_0_3": "Style3D Atelier&Simulator",
    "global_home_header_submenu_link_0_3_des": "",
    "global_home_header_submenu_link_0_3_des2": "",
    "global_home_header_submenu_link_0_4": "Style3D Market",
    "global_home_header_submenu_link_0_4_des": "时尚灵感素材库",
    "global_home_header_submenu_link_0_4_des2": "在线获取海量3D数字资源，为创意加速度",
    "global_home_header_submenu_link_0_5": "Style3D Atelier",
    "global_home_header_submenu_link_0_5_des": "专为动画、视觉特效和游戏开发量身定制的3D服装建模和动画制作软件",
    "global_home_header_submenu_link_0_5_des2": "服务于影视、游戏、动画行业，提供高效的数字服装建模能力",
    "global_home_header_submenu_link_0_5_des3": "轻松创建、模拟、呈现高保真的数字服装",
    "global_home_header_submenu_link_0_6": "Style3D Simulator",
    "global_home_header_submenu_link_0_6_des": "虚幻引擎数字服装模拟插件，能实现高质量的实时布料模拟",
    "global_home_header_submenu_link_0_6_des2": "一款虚幻引擎数字服装模拟插件，兼备实时、离线模式，让数字服装栩栩如生",
    "global_home_header_submenu_link_0_6_des3": "用于任何UE项目，提升服装动画制作效率",
    "global_home_header_submenu_link_0_8": "Style3D MixMatch",
    "global_home_header_submenu_link_0_8_des": "跨平台3D服装实时搭配软件",
    "global_home_header_submenu_link_0_8_des2": "虚拟服装搭配出图",
    "global_home_header_submenu_link_0_8_des3": "无需实物样衣，轻松运用3D虚拟服装完成时尚搭配",
    "global_home_header_menu_1": "解决方案",
    "global_home_header_submenu_link_1_0": "品牌商",
    "global_home_header_submenu_link_1_0_des": "无论大小品牌，均能找到适配的数字化解决方案",
    "global_home_header_submenu_link_1_0_des2": "",
    "global_home_header_submenu_link_1_1": "制造商",
    "global_home_header_submenu_link_1_1_des": "核心业务场景强关联，以数字化赋能企业竞争力",
    "global_home_header_submenu_link_1_1_des2": "",
    "global_home_header_submenu_link_1_2": "面辅料商",
    "global_home_header_submenu_link_1_2_des": "解决为企业提供“管理+协同+展销”一站式面料数字化解决方案",
    "global_home_header_submenu_link_1_2_des2": "",
    "global_home_header_submenu_link_1_3": "教育行业",
    "global_home_header_submenu_link_1_3_des": "从产业创新应用实践中来 到院校人才培养赋能中去",
    "global_home_header_submenu_link_1_3_des2": "",
    "global_home_header_submenu_link_1_4": "虚拟时尚",
    "global_home_header_submenu_link_1_4_des": "",
    "global_home_header_submenu_link_1_4_des2": "",
    "global_home_header_menu_2": "服务支持",
    "global_home_header_submenu_link_2_0": "内容服务",
    "global_home_header_submenu_link_2_0_des": "全方位综合展示服装和面料资产",
    "global_home_header_submenu_link_2_0_des2": "",
    "global_home_header_submenu_link_2_1": "客户成功",
    "global_home_header_submenu_link_2_1_des": "协助企业培养数字化应用人才",
    "global_home_header_submenu_link_2_1_des2": "",
    "global_home_header_submenu_link_2_2": "帮助中心",
    "global_home_header_submenu_link_2_2_des": "",
    "global_home_header_submenu_link_2_2_des2": "",
    "global_home_header_submenu_link_2_3": "咨询服务",
    "global_home_header_submenu_link_2_3_des": "赋能企业生态发展和运营模式",
    "global_home_header_submenu_link_2_3_des2": "",
    "global_home_header_submenu_link_2_4": "培训服务",
    "global_home_header_submenu_link_2_4_des": "协助企业培养数字化应用人才，使数字化解决方案更好的在企业中落地",
    "global_home_header_submenu_link_2_4_des2": "",
    "global_home_header_menu_3": "资源中心",
    "global_home_header_submenu_link_3_0": "学习中心",
    "global_home_header_submenu_link_3_0_des": "",
    "global_home_header_submenu_link_3_0_des2": "",
    "global_home_header_submenu_link_3_1": "合作伙伴",
    "global_home_header_submenu_link_3_1_des": "携手共创3D行业未来",
    "global_home_header_submenu_link_3_1_des2": "",
    "global_home_header_submenu_link_3_2": "客户案例",
    "global_home_header_submenu_link_3_2_des": "",
    "global_home_header_submenu_link_3_2_des2": "",
    "global_home_header_submenu_link_3_3": "公司新闻",
    "global_home_header_submenu_link_3_3_des": "Style3D近期动态和资讯",
    "global_home_header_submenu_link_3_3_des2": "",
    "global_home_header_submenu_link_3_4": "博客",
    "global_home_header_submenu_link_3_4_des": "打造数字引擎 驱动时尚未来",
    "global_home_header_submenu_link_3_4_des2": "",
    "global_home_header_submenu_link_3_5": "客户案例",
    "global_home_header_submenu_link_3_5_des": "数字化赋能全球客户提效",
    "global_home_header_submenu_link_3_5_des2": "",
    "global_home_header_submenu_link_3_6": "市场活动",
    "global_home_header_submenu_link_3_6_des": "营销活动目录和详细信息",
    "global_home_header_submenu_link_3_6_des2": "",
    "global_home_header_submenu_link_3_7": "设计大赛",
    "global_home_header_submenu_link_3_7_des": "引领2024奥运新风尚！Style3D设计大赛等你来挑战",
    "global_home_header_submenu_link_3_7_des2": "",
    "global_home_header_submenu_link_3_8": "帮助中心",
    "global_home_header_submenu_link_3_8_des": "获取产品使用常见问题的解答",
    "global_home_header_submenu_link_3_8_des2": "",
    "global_home_header_submenu_link_3_9": "",
    "global_home_header_submenu_link_3_9_des": "",
    "global_home_header_submenu_link_3_9_des2": "",
    "global_home_header_menu_4": "关于我们",
    "global_home_header_submenu_link_4_0": "公司介绍",
    "global_home_header_submenu_link_4_0_des": "以全球领先技术，引领3D数字时尚新范式",
    "global_home_header_submenu_link_4_0_des2": "",
    "global_home_header_submenu_link_4_1": "企业社会责任",
    "global_home_header_submenu_link_4_1_des": "科技创新，时尚向善",
    "global_home_header_submenu_link_4_1_des2": "",
    "global_home_header_submenu_link_4_2": "奖学金计划",
    "global_home_header_submenu_link_4_2_des": "投资未来，持续成长",
    "global_home_header_submenu_link_4_2_des2": "",
    "global_home_header_form_title_1_left": "Style3D",
    "global_home_header_form_title_1_right": "试用",
    "global_home_header_form_title_2": "Style3D试用",
    "global_home_header_form_key_1": "联系人",
    "global_home_header_form_placeholder_1": "请输入您的称呼",
    "global_home_header_form_key_2": "联系电话",
    "global_home_header_form_placeholder_2": "请输入您的手机号",
    "global_home_header_form_key_3": "验证码",
    "global_home_header_form_placeholder_3": "请输入短信验证码",
    "global_home_header_form_key_4": "电子邮箱",
    "global_home_header_form_placeholder_4": "请输入您的电子邮箱",
    "global_home_header_form_key_5": "企业名称",
    "global_home_header_form_placeholder_5": "请填写您所在企业的名称",
    "global_home_header_form_key_6a": "职位类型",
    "global_home_header_form_placeholder_6a": "请选择您的职位类型",
    "global_home_header_form_key_6": "企业类型",
    "global_home_header_form_placeholder_6": "请选择您的企业类型",
    "global_home_header_form_key_7": "营收规模",
    "global_home_header_form_placeholder_7": "请选择您的企业营收规模",
    "global_home_header_form_conform_text": "阅读并接受",
    "global_home_header_form_conform_policy": "《Style3D平台隐私政策》",
    "global_home_header_form_help": "客服咨询：400-158-0699",
    "global_home_footer_info_address": "浙江省杭州市西湖区蒋村街道双龙街99号三深国际中心A座3楼",
    "global_home_footer_info_tel": "官方热线: 400-158-0699",
    "global_home_footer_info_businessemail": "商务合作:",
    "global_home_footer_info_mediaemail": "媒体合作: ",
    "global_home_footer_copyright": "Copyright © 2024 凌迪科技版权所有/",
    "global_swipper_title": "Style3D",
    "global_cloud_swipper_colorTitle_0": "Cloud",
    "global_fabric_swipper_colorTitle_1": "Fabric",
    "global_studio_swipper_colorTitle_2": "Studio",
    "global_studio_swipper_colorTitle_3": "Atelier",
    "global_studio_swipper_colorTitle_4": "Simulator",
    "global_studio_swipper_colorTitle_5": "One",
    "global_studio_swipper_colorTitle_6": "MixMatch",
    "global_fabric_colorTitle": "Style3D Fabric",
    "global_fabric_colorTitle3": "Style3D Fabric",
    "global_fabric_colorTitle1": "Style3D Scanner",
    "global_fabric_colorTitle2": "Style3D Tester",
    "global_fabric_title_0": "快速精确实现面料数字化",
    "global_fabric_subTitle_0": "软硬件协同，有效提升面料数字化的精准度",
    "global_fabric_content_text_00": "实样扫描测量",
    "global_fabric_content_text_01": "面料数字化处理",
    "global_fabric_content_text_02": "面料资产入库",
    "global_fabric_title_1": "硬件捕捉面料属性",
    "global_fabric_content_img_title_0": "面料3D扫描仪",
    "global_fabric_content_text_10": "精准捕捉纹理细节，呈现清晰图像和真实色彩",
    "global_fabric_content_text_11": "真实还原各种面料质感，全自动获取PBR多种贴图",
    "global_fabric_content_text_12": "直连Style3D Fabric软件，实现面料智能处理的无缝连接",
    "global_fabric_content_img_title_1": "面料弯曲测量仪 & 面料拉伸测量仪",
    "global_fabric_content_img_title_1_mobile": "面料弯曲测量仪 & 面料拉伸测量仪",
    "global_fabric_content_text_13": "精确测量克重、厚度、拉伸和弯曲物理属性",
    "global_fabric_content_text_14": "无需手动输入，实现拉伸和弯曲的全自动化测量",
    "global_fabric_content_text_15": "科学的分析面料特性，精准还原真实面料",
    "global_fabric_content_text_16": "面料精确还原现实",
    "global_fabric_title_2": "软件实时编辑预览数字面料",
    "global_fabric_subTitle_2": "快速编辑、调整数字面料，可即时预览面料上身效果",
    "global_fabric_content_text_26": "多维度面料特性展示",
    "global_fabric_content_text_27": "一键面料循环、换色、齐色处理",
    "global_fabric_content_text_28": "支持实时渲染和离线渲染",
    "global_fabric_content_text_29": "高仿真面料即时上身呈现",
    "global_fabric_content_text_20": "颜色贴图",
    "global_fabric_content_text_21": "法线贴图",
    "global_fabric_content_text_22": "透明贴图",
    "global_fabric_content_text_23": "金属贴图",
    "global_fabric_content_text_24": "粗糙度贴图",
    "global_fabric_content_text_25": "置换贴图",
    "global_cloud_title_0": "全面的生态协同",
    "global_cloud_subTitle_0": "基于数字资产在企业间高效流转和研发协作，提升经营效率",
    "global_cloud_title_1": "灵活的资产管理",
    "global_cloud_subTitle_1": "助力多元资产在线管理，可视化呈现，随需取用",
    "global_cloud_title_2": "高效的在线设计",
    "global_cloud_subTitle_2": "实现数字服装快速改款创款，部件定制，随心而动",
    "global_cloud_title_3": "丰富的营销工具",
    "global_cloud_subTitle_3": "通过在线推款、数字展厅，助力生意拓展",
    "global_cloud_performance_title_0": "开放性",
    "global_cloud_performance_subTitle_0": "支持以SDK、API的方式进行系统对接",
    "global_cloud_performance_title_1": "兼容性",
    "global_cloud_performance_subTitle_1": "兼容市场上主流建模软件的资产格式，如OBJ、FBX、GITF、GLB、SBSAR、DXF 等",
    "global_cloud_performance_title_2": "安全性",
    "global_cloud_performance_subTitle_2": "拥有权威安全证书认证的全球范围云安全能力",
    "global_cloud_performance_title_3": "多端支持",
    "global_cloud_performance_subTitle_3": "电脑端、移动端",
    "global_atelier_title_0": "专业的数字服装建模工具",
    "global_atelier_content_0": "高效率和高性能",
    "global_atelier_content_0_subtext": "多种风格款式可套用、自由高效修改、支持AI读图生成新姿势，1小时可制作1套数字服装",
    "global_atelier_content_1": "海量资产",
    "global_atelier_content_1_subtext": "服装款式、面料和辅料素材、人台及动画模型，超过4W+优质资产",
    "global_atelier_content_2": "兼容性和开放性",
    "global_atelier_content_2_subtext": "兼容主流3D格式，支持FBX、OBJ、ABC、USD、SMD等，与UnrealEngine、NVIDIA Omniverse无缝衔接",
    "global_atelier_video_des_0": "服装制作：亚麻zero，渲染灯光：大反派猫大人",
    "global_atelier_video_text": "Style3D Atelier主要用于超写实服装建模，基于自研模拟仿真引擎，可以轻松实现5-7层服装的快速解算效果",
    "global_simulator_title_0": "一款虚幻引擎的插件<br>用于实现工业级别的高级服装模拟",
    "global_simulator_content_0": "完全兼容Style3D数字资产",
    "global_simulator_content_0_subtext": "能够在Unreal Engine中直接使用由Style3D提供的数字服装资产",
    "global_simulator_content_1": "有尖端的CG技术驱动的实时服装模拟",
    "global_simulator_content_1_subtext": "支持在虚拟场景中实施模拟复杂服装，包括高达5万个顶点的服装",
    "global_simulator_content_2": "支持动画缓存",
    "global_simulator_content_2_subtext": "以小体积格式保存高品质服装动画，方便后期处理于播放",
    "global_simulator_video_text": "Style3D Simulator 作为超写实服装仿真插件，兼具实时和离线两种应用能力。<br>Simulator最为突出的就是超写实服装的实时仿真互动，在<span class='color'>5W</span>服装顶点数的情况下，仍可维持<span class='color'>45FPS+</span>的实时帧率。",
    "global_studio_inspiration_card_0": "全球领先的商业化布料仿真GPU，较CPU性能<span class='color'>提升4倍</span>",
    "global_studio_inspiration_card_1": "独家<span class='color1'>柔性仿真模拟技术</span>",
    "global_studio_inspiration_card_2": "强强联手 VulkanAPI 渲染技术",
    "global_studio_inspiration_card_3": "支持局部毛发等复杂场景的离线渲染",
    "global_studio_inspiration_cpu": "CPU性能",
    "global_studio_inspiration_gpu": "GPU性能",
    "global_studio_number_card_0": "海量素材资源持续增长！不仅限于趋势、廓形、面料等",
    "global_studio_number_card_1": "支持多种复杂工艺效果，如刺绣、龟裂、胶印等",
    "global_studio_number_card_2": "定制化服装展示，不同尺码匹配不同模特",
    "global_studio_number_card_3": "打造专属模特，随心调整模特姿势、体型、表情",
    "global_studio_process_card_0": "电脑端和移动端同步，无缝对接设计进度",
    "global_studio_process_card_1": "精简内外沟通，实时验证创意与版型效果",
    "global_studio_process_card_2": "与主流2D CAD软件联动，连接自动排料、物料单等生产资料",
    "global_studio_colorTitle": "Style3D studio",
    "global_studio_title_0": "更真，更快，更强劲的3D视效呈现",
    "global_studio_subTitle_0": "实时沟通细节，在线一键改款",
    "global_studio_title_1": "海量灵感库，高度定制化让3D设计轻松无界",
    "global_studio_subTitle_1": "搭载海量灵感库",
    "global_studio_title_2": "丰富素材与定制模特",
    "global_studio_title_3": "快速创作数字服装",
    "global_studio_subTitle_3": "3D可视化呈现面料、版型、人台，实时编辑服装效果",
    "global_studio_title_4": "AI技术加持，轻松还原服装与模特真实效果",
    "global_studio_subTitle_4": "使用AI工具高度还原真实模特样貌、精确仿真服装布料细节",
    "global_studio_title_5": "展现高质量的服装仿真效果",
    "global_studio_subTitle_5": "利用实时渲染等工具快速、精准地输出3D服装效果",
    "global_studio_title_6": "多软件协同联动",
    "global_studio_subTitle_6": "可联动多种制版软件及其它2D、3D软件，让设计创意不受限",
    "global_studio_title_7": "高精还原真实工艺",
    "global_studio_subTitle_7": "自有布料仿真技术及多种工艺类工具加持，细致还原服装真实工艺",
    "global_studio_title_8": "视效创意无限表达",
    "global_studio_subTitle_8": "打破创意限制，让品牌宣传如虎添翼",
    "global_studio_FAQs": "常见问题",
    "global_studio_question_0": "Q：如何下载试用？",
    "global_studio_answertext_0": "A：点击页面右上角",
    "global_studio_answertext_1": "申请试用",
    "global_studio_answertext_2": "按钮",
    "global_studio_question_1": "Q：Style3D Studio的推荐配置？",
    "global_studio_answer_1": "A：请查看<a target=\"_blank\" href='https://www.style3d.com/public/helper/detail/grid/style3dsoftware/123'>配置要求说明</a>",
    "global_studio_question_2": "Q：如何了解更多产品信息？",
    "global_studio_answer_2": "A：请访问我们的<a target=\"_blank\" href='https://www.style3d.com/public/helper/style3dsoftware/grid'>帮助中心</a>",
    "global_atelier_colorTitle_0": "Style3D Atelier",
    "global_atelier_colorTitle_1": "Style3D Simulator",
    "global_atelier_swipper_colorTitle_3": "Atelier & Simulator",
    "global_atelier_swipper_subTitle_3": "用Style3D Atelier&Meta创造惊艳的 3D 服装效果",
    "global_atelier_swipper_purpletext": "Atelier",
    "global_atelier_swipper_greentext": "Simulator",
    "global_atelier_swipper_whitetext": "无论是游戏、动画、电影还是其他CG项目，都可以用这套工具集轻松创建、模拟、渲染各种服装。",
    "global_atelier_tool_title": "专业的数字服装建模工具<br>创建<span class='gradientText'>高质量、超现实</span>的数字服装",
    "global_atelier_tool_card_title_0": "快速和简单",
    "global_atelier_tool_card_subtitle_0": "无需复杂的操作技巧，Atelier直观、强大的工具帮你在几分钟内快速完成一件服装的设计调整和建模。",
    "global_atelier_tool_card_title_1": "逼真和精细",
    "global_atelier_tool_card_subtitle_1": "搭载高性能GPU模拟布料的重量、摩擦、弹性等属性，高仿真还原服装款式细节、材质。",
    "global_atelier_tool_card_title_2": "兼容性和灵活性",
    "global_atelier_tool_card_subtitle_2": "兼容主流3D格式，例如 FBX、OBJ、ABC、SMD等，与UV编辑器、NVIDIA、Omniverse平台无缝连接。",
    "global_atelier_plugin_title": "虚幻引擎实时服装动画插件<br>轻松<span class='gradientText'>提升工作效率</span>和<span class='gradientText2'>创意表达</span>",
    "global_atelier_plugin_card_title_0": "建模-动画链路提速<span class='colorText'>70%</span>",
    "global_atelier_plugin_card_subtitle_0": "联动Style3D Atelier建模工具，通过Meta进行高效缓存和渲染，以更短时间获得更高的画质和性能，<br>更好地展示你的作品。",
    "global_atelier_plugin_card_title_1": "实时仿真，可支持多角色同时驱动",
    "global_atelier_plugin_card_subtitle_1": "对多层布料、大幅度动作等复杂场景进行实时解算，无需等待烘焙或导出，即刻预览和调整服装<br>的效果和动作。",
    "global_atelier_plugin_card_title_2": "兼容性和灵活性",
    "global_atelier_plugin_card_subtitle_2": "通过鼠标可以灵活拖拽服装，查看服装的实时变化，并且同一款服装可以自动匹配不同身型，呈现相<br>应的上身效果。",
    "global_atelier_card_button1": "下载试用版",
    "global_atelier_card_button2": "配置要求",
    "global_mixmatch_title_0": "无限搭配，释放创意",
    "global_mixmatch_subTitle_0": "基于自研模拟仿真引擎，助力您轻松完成多款式、多层次、多穿法的时尚搭配",
    "global_mixmatch_title_1": "海量素材，随心展示",
    "global_mixmatch_subTitle_1": "内置预设多种模特、配饰、姿势、场景等资产，供您随心选择",
    "global_mixmatch_title_2": "多适配模式，应用延伸",
    "global_mixmatch_subTitle_2": "PC模式、手机模式、大屏模式、竖屏模式等多种模式，以适配您多样的应用场景",
    "global_mixmatch_title_3": "产品优势",
    "global_mixmatch_title_4": "应用场景",
    "global_mixmatch_collocation_text_0": "层次改变",
    "global_mixmatch_collocation_text_1": "造型调整",
    "global_mixmatch_mode_title_0": "竖屏",
    "global_mixmatch_mode_title_1": "移动端",
    "global_mixmatch_mode_text_0": "竖屏模式视觉效果提升",
    "global_mixmatch_mode_text_1": "现场体验互动升级",
    "global_mixmatch_mode_text_2": "搭配&试穿效果触手可得",
    "global_mixmatch_mode_text_3": "形成搭配推荐、尺码推荐",
    "global_mixmatch_advantages_title_0": "无需实物样衣",
    "global_mixmatch_advantages_title_1": "灵活高效",
    "global_mixmatch_advantages_title_2": "成本更低",
    "global_mixmatch_advantages_title_3": "操作简单",
    "global_mixmatch_advantages_follow_0": "不需等待实物样衣即可开始搭配",
    "global_mixmatch_advantages_follow_1": "快速穿搭，平均10分钟可完成30套服装搭配",
    "global_mixmatch_advantages_follow_2": "0模特费用，0场地费用",
    "global_mixmatch_advantages_follow_3": "使用者无需3D基础，3分钟上手",
    "global_mixmatch_scenarios_title_0": "设计研发",
    "global_mixmatch_scenarios_title_1": "订货会",
    "global_mixmatch_scenarios_title_2": "店铺搭配指引",
    "global_mixmatch_scenarios_title_3": "电商拍摄",
    "global_mixmatch_scenarios_subTitle_0": "助力服装设计师高效输出高质量的搭配方案",
    "global_mixmatch_scenarios_subTitle_1": "增强现场互动，带来新的流量与订单",
    "global_mixmatch_scenarios_subTitle_2": "赋能搭配师、陈列师高效输出搭配方案，给到门店更多搭配指引信息",
    "global_mixmatch_scenarios_subTitle_3": "拍摄搭配方案提前准备",
    "global_solutions_edu_topic_title": "Education",
    "global_solutions_edu_topic_subtitle": "教育行业解决方案",
    "global_solutions_edu_title_0": "纺织服装全链路数字化人才培养解决方案",
    "global_solutions_edu_title_1": "数字化人才培养方向",
    "global_solutions_edu_part_1_item_title_1": "创意类",
    "global_solutions_edu_part_1_item_subtitle_11": "数字化服装企划",
    "global_solutions_edu_part_1_item_subtitle_12": "图案设计师",
    "global_solutions_edu_part_1_item_subtitle_13": "款式设计师",
    "global_solutions_edu_part_1_item_title_2": "制造类",
    "global_solutions_edu_part_1_item_subtitle_21": "数字化版师",
    "global_solutions_edu_part_1_item_subtitle_22": "3D建模师",
    "global_solutions_edu_part_1_item_subtitle_23": "数字化业务专员",
    "global_solutions_edu_part_1_item_title_3": "运营类",
    "global_solutions_edu_part_1_item_subtitle_31": "数字化项目经理",
    "global_solutions_edu_part_1_item_subtitle_32": "数字化产品运营",
    "global_solutions_edu_title_2": "专业建设解决方案",
    "global_solutions_edu_part_2_text1": "产业学院共建",
    "global_solutions_edu_part_2_text2": "纺织服装数字资源平台",
    "global_solutions_edu_part_2_text3": "纺织服装数字化实验室/实训室",
    "global_solutions_edu_part_2_text4": "元宇宙数字时尚展示中心",
    "global_solutions_edu_part_2_text5": "纺织服装虚拟仿真项目",
    "global_solutions_edu_part_2_text6": "纺织面料数字化方案",
    "global_solutions_edu_title_3": "数字化课程资源",
    "global_solutions_edu_part_3_item_title_1": "专业的 <br> 课程设置",
    "global_solutions_edu_part_3_item_subtitle_11": "紧跟行业能力要求设置学习目标",
    "global_solutions_edu_part_3_item_subtitle_12": "素养与能力相结合的课程设置",
    "global_solutions_edu_part_3_item_subtitle_13": "配套学习平台和海量学习资源",
    "global_solutions_edu_part_3_item_subtitle_14": "完善的能力考核体系",
    "global_solutions_edu_part_3_item_title_2": "模块化 <br> 课程建设",
    "global_solutions_edu_part_3_item_subtitle_21": "西装/连衣裙/牛仔裤/羽绒服/配饰数字化开发",
    "global_solutions_edu_part_3_item_subtitle_22": "成衣走秀",
    "global_solutions_edu_part_3_item_subtitle_23": "陈列展示",
    "global_solutions_edu_part_3_item_title_3": "完善的 <br> 教学资源",
    "global_solutions_edu_part_3_item_subtitle_31": "课程标准、教材",
    "global_solutions_edu_part_3_item_subtitle_32": "项目教学设计、项目案例",
    "global_solutions_edu_part_3_item_subtitle_33": "教学素材、教学视频、试题",
    "global_solutions_edu_part_3_item_centerText": "打造高校与服装行业的桥梁",
    "global_solutions_edu_part_3_item_title_4": "培训",
    "global_solutions_edu_part_3_item_subtitle_41": "师资培训",
    "global_solutions_edu_part_3_item_subtitle_42": "社会培训",
    "global_solutions_edu_part_3_item_subtitle_43": "学生培训",
    "global_solutions_edu_part_3_item_subtitle_44": "技能考核认证",
    "global_solutions_edu_part_3_item_title_5": "学科竞赛/技能大赛",
    "global_solutions_edu_part_3_item_subtitle_51": "竞赛组织",
    "global_solutions_edu_part_3_item_subtitle_52": "竞赛承办",
    "global_solutions_edu_part_3_item_subtitle_53": "参赛指导",
    "global_solutions_edu_part_3_item_title_6": "增值服务",
    "global_solutions_edu_part_3_item_subtitle_61": "科研课题合作",
    "global_solutions_edu_part_3_item_subtitle_62": "产业名师讲堂",
    "global_solutions_edu_part_3_item_subtitle_63": "就业指导推荐",
    "global_solutions_edu_title_4": "教育合作伙伴",
    "global_solutions_edu_part_4_description": "与教育同行，共创3D时尚未来",
    "global_solutions_brand_swipper_title": "Brand",
    "global_solutions_brand_swipper_subtitle": "品牌商解决方案",
    "global_solutions_brand_swipper_title_0": "行业挑战",
    "global_solutions_brand_swipper_card_title_0": "设计企划不直观",
    "global_solutions_brand_swipper_card_subtitle_0": "规划方向大概不确定，理念呈现抽象不直观，信息传达不清晰，人物解析成本高",
    "global_solutions_brand_swipper_card_text_0": "数字创意助力",
    "global_solutions_brand_swipper_card_title_1": "研发评审效率低",
    "global_solutions_brand_swipper_card_subtitle_1": "面料开发、样衣制作、版型调整需反复修改或寄样，沟通成本高，过程时间久，物料浪费多。",
    "global_solutions_brand_swipper_card_text_1": "数字协同开发",
    "global_solutions_brand_swipper_card_title_2": "搭配组货受局限",
    "global_solutions_brand_swipper_card_subtitle_2": "受样衣到货时间限制，难以在短时间全量组货、订货，或盲选、或意向选。",
    "global_solutions_brand_swipper_card_text_2": "数字齐货组货",
    "global_solutions_brand_swipper_card_title_3": "上市推广成本高",
    "global_solutions_brand_swipper_card_subtitle_3": "陈列、售卖指导、推广等视效物料拍摄制作成本高，周期紧，费时费力，且需求量极大。",
    "global_solutions_brand_swipper_card_text_3": "数字高效营销",
    "global_solutions_brand_swipper_card_title_4": "设计企划",
    "global_solutions_brand_swipper_card_subtitle_4": "大量时间用于前期信息收集和市场调研，真正留给策划的时间有限",
    "global_solutions_brand_swipper_card_title_5": "设计研发",
    "global_solutions_brand_swipper_card_subtitle_5": "面料开发、样衣制作、版型调整需反复修改寄样，沟通成本高、时间久",
    "global_solutions_brand_swipper_card_title_6": "评审选款",
    "global_solutions_brand_swipper_card_subtitle_6": "传统展示方式效果、时间有限，影响决策有效性",
    "global_solutions_brand_swipper_card_title_7": "营销推广",
    "global_solutions_brand_swipper_card_subtitle_7": "物料拍摄成本高，人力投入大，制作周期紧",
    "global_solutions_brand_Arrows_title_1": "数字化企划，让灵感孵化",
    "global_solutions_brand_Arrows_titleCN_1": "企划 & 设计解决方案",
    "global_solutions_brand_Arrows_subtitle_1": "整合当下热门趋势和灵感素材，加速企划流程",
    "global_solutions_brand_Arrows_rightText_1_1": "超强兼容和编辑能力的云协作平台，让思维可视化、具象化，助力高效企划设计",
    "global_solutions_brand_Arrows_rightText_1_2": "紧跟时尚前沿，高频更新的趋势精选推荐，助力品牌获取时尚讯息，灵感参考",
    "global_solutions_brand_Arrows_rightText_1_3": "海量精美创意模板，快速呈现规划意图和创意理念",
    "global_solutions_brand_Arrows_title_2": "实时协作，3D提速产研",
    "global_solutions_brand_Arrows_titleCN_2": "研发 & 评审解决方案",
    "global_solutions_brand_Arrows_subtitle_2": "面料、版型、样衣实时调整，即时呈现",
    "global_solutions_brand_Arrows_rightText_2_1": "通过AIGC智能创款，传承品牌基因，释放创意灵感",
    "global_solutions_brand_Arrows_rightText_2_2": "基于CAD板片到3D自动缝合，提升研发效率，减少样衣成本",
    "global_solutions_brand_Arrows_rightText_2_3": "顶尖的柔性仿真技术，从面料设计到款式设计精准呈现和还原",
    "global_solutions_brand_Arrows_title_3": "评审选款3D化，提升决策效率",
    "global_solutions_brand_Arrows_titleCN_3": "搭配组货解决方案",
    "global_solutions_brand_Arrows_subtitle_3": "还原真实陈列、搭配效果，辅助内外部决策",
    "global_solutions_brand_Arrows_rightText_3_1": "3D轻量化穿搭模拟，简单直观，提升搭配组货效率",
    "global_solutions_brand_Arrows_rightText_3_2": "360°沉浸式虚拟穿搭，增强试穿体验，提升产品形象和销售连带，提升研发效率，减少样衣成本",
    "global_solutions_brand_Arrows_rightText_3_3": "结构性分析盘点，精准把控货品上架节奏，提升商品管控能力",
    "global_solutions_brand_Arrows_title_4": "赋能营销，加速电商上新",
    "global_solutions_brand_Arrows_titleCN_4": "推广零售解决方案",
    "global_solutions_brand_Arrows_subtitle_4": "突破传统拍摄限制，3D视效抓人眼球",
    "global_solutions_brand_Arrows_rightText_4_1": "基于3D跨时空虚拟仿真走秀，低成本高价值赋能营销视效",
    "global_solutions_brand_Arrows_rightText_4_2": "任意细节高清渲染爆量短视频，让经典看得见，让营销推款更随意",
    "global_solutions_brand_Arrows_rightText_4_3": "标准化全套全链路出图模式，让上新更简单",
    "global_solutions_brand_titleimage_1": "服务目标",
    "global_solutions_brand_titleimage_2": "3D链路",
    "global_solutions_brand_followimage_2": "3D数字技术，替代更多实物设计打样，减少研发成本，低碳环保，助力企业降本增效。<br>通过3D服装资产，进行穿搭视效呈现，提升产品竞争力和吸引力，助力商品连带和获客增收。",
    "global_solutions_brand_followimage_moblie_2": "3D数字技术，替代更多实物设计打样，减少研发成本，低碳环保，助力企业降本增效。通过3D服装资产，进行穿搭视效呈现，提升产品竞争力和吸引力，助力商品连带和获客增收。",
    "global_solutions_brand_titleimage_3": "解决方案矩阵",
    "global_solutions_brand_chainImg_title": "构建品牌为核心的数字生态链",
    "global_solutions_brand_panoramagram_title_1": "数字时尚产业全景图",
    "global_solutions_brand_panoramagram_title_2": "数字化串联营销链路，打造您的金牌业务员",
    "global_solutions_brand_panoramagram_title_3": "用数字化赋能销售链路，\n打造金牌销售为企业赢得新机会",
    "global_solutions_manufacturer_Arrows_describe_5": "数字化面料样卡",
    "global_solutions_manufacturer_Arrows_describe_9": "*以制作单款5齐色羽绒服为例",
    "global_solutions_manufacturer_Arrows_describe_6": "快准省的3D打样间",
    "global_solutions_manufacturer_Arrows_describe_7": "一物一码的成衣展厅",
    "global_solutions_manufacturer_Arrows_describe_8": "一站式面料解决方案,硬件软件无缝衔接。",
    "global_solutions_brand_panoramagram_subtitle_1": "连通制造商、品牌商、面料商，实现生态协同",
    "global_solutions_fabric_panoramagram_subtitle_1": "连通面料商，制造商、品牌商、实现生态协同",
    "global_solutions_manufacturer_swipper_title": "Manufacturer",
    "global_solutions_manufacturer_swipper_subtitle": "制造商解决方案",
    "global_solutions_manufacturer_swipper_card_title_0": "资产管理",
    "global_solutions_manufacturer_swipper_card_subtitle_0": "实体库存多而杂<br>线下选款效率低、速度慢",
    "global_solutions_manufacturer_swipper_card_title_1": "研发协同",
    "global_solutions_manufacturer_swipper_card_subtitle_1": "内外协同效率低<br>样衣调整反馈耗时久",
    "global_solutions_manufacturer_swipper_card_title_2": "展销获客",
    "global_solutions_manufacturer_swipper_card_subtitle_2": "展销获客局限多<br>面料展现形式和数量有限",
    "global_solutions_manufacturer_Arrows_title_1": "打造数字化设计中心",
    "global_solutions_manufacturer_Arrows_subtitle_1": "数字资产",
    "global_solutions_manufacturer_Arrows_describe_1": "2D&3D资产上云",
    "global_solutions_manufacturer_Arrows_title_2": "沉淀3D数字资产",
    "global_solutions_manufacturer_Arrows_subtitle_2": "虚实结合协同",
    "global_solutions_manufacturer_Arrows_describe_2": "AI智能海选推款",
    "global_solutions_manufacturer_Arrows_describe_3": "展厅、官网数字化",
    "global_solutions_manufacturer_Arrows_title_3": "升级企业官网，搭建VR展厅",
    "global_solutions_manufacturer_Arrows_subtitle_3": "全域营销",
    "global_solutions_manufacturer_Arrows_describe_4": "一物一码连接实物到3D",
    "global_solutions_manufacturer_panoramagram_title_1": "",
    "global_solutions_manufacturer_panoramagram_subtitle_1": "",
    "global_solutions_manufacturer_Arrows_title_4": "线下成衣展厅实现一物一码",
    "global_solutions_manufacturer_Arrows_title_5": "让样衣管理手到擒来",
    "global_solutions_manufacturer_arrows_video_bottom_text": "虚实结合让样衣研发更精准",
    "global_solutions_manufacturer_arrows_card_title_1": "设计研发",
    "global_solutions_manufacturer_arrows_card_title_2": "改版改款",
    "global_solutions_manufacturer_arrows_card_title_3": "齐色应用",
    "global_solutions_fabric_swipper_title": "Fabric",
    "global_solutions_fabric_swipper_subtitle": "面辅料商解决方案",
    "global_solutions_fabric_swipper_card_title_0": "资产管理",
    "global_solutions_fabric_swipper_card_subtitle_0": "库存管理效率低<br>面料调取不能实时同步",
    "global_solutions_fabric_swipper_card_title_1": "研发协同",
    "global_solutions_fabric_swipper_card_subtitle_1": "业务沟通周期久<br>内外协同反馈慢",
    "global_solutions_fabric_swipper_card_title_2": "展销获客",
    "global_solutions_fabric_swipper_card_subtitle_2": "展销获客局限多<br>面料展现形式和数量有限",
    "global_solutions_fabric_Arrows_title_1": "打造面料数字化工作站",
    "global_solutions_fabric_Arrows_describe_1": "软硬兼施，让面料实体数字化",
    "global_solutions_fabric_Arrows_describe_2": "一键推料，进销存数据更清晰",
    "global_solutions_fabric_Arrows_title_2": "升级面料样卡和样册",
    "global_solutions_fabric_Arrows_describe_3": "线下见实样，线上看效果",
    "global_solutions_fabric_Arrows_describe_4": "虚实结合，面料一键成衣",
    "global_solutions_fabric_Arrows_title_3": "线下展厅实现一物一码",
    "global_solutions_fabric_Arrows_describe_5": "面料数字展厅，线上线下无缝对接",
    "global_solutions_fabric_Arrows_describe_6": "从2D到3D，感知不同面料质感",
    "global_solutions_fabric_Arrows_title_4": "升级企业的线上官网",
    "global_solutions_fabric_Arrows_title_5": "使面料进销存一目了然",
    "global_solutions_fabric_panoramagram_title_1": "",
    "global_support_customer_success_swipper_title": "Training Service",
    "global_support_customer_success_swipper_subtitle": "培训服务",
    "global_support_consultation_header_title": "Style3D以“AI + 3D”技术为核心驱动力，致力于全球时尚行业数字化转型，帮助客户制定和实施数字化战略规划，推动设计研发数字化、供应链生态数字化、营销运营数字化，推动数字化和人工智能的应用，营造更高效、可持续的企业生态发展和运营模式。",
    "global_support_consultation_service_title_1": "咨询服务优势",
    "global_support_consultation_service_title_2": "服务内容与流程",
    "global_support_consultation_service_title_3": "应用落地场景",
    "global_support_consultation_service_content_1_title_1": "技术创新驱动",
    "global_support_consultation_service_content_1_follow_1": "以技术为核心驱动力，赋能创新发展和业务增长",
    "global_support_consultation_service_content_1_title_2": "资深行业专家",
    "global_support_consultation_service_content_1_follow_2": "头部企业背景资深行业专家，提供深度业务支持",
    "global_support_consultation_service_content_1_title_3": "全程陪伴式服务",
    "global_support_consultation_service_content_1_follow_3": "针对项目生命周期，全程提供深度辅导服务",
    "global_support_consultation_service_content_3_title_1": "制造商场景",
    "global_support_consultation_service_content_3_title_2": "品牌商场景",
    "global_support_customer_success_title_0": "培训服务特色",
    "global_support_customer_success_content_0_title_0": "系统定制",
    "global_support_customer_success_content_0_follow_0": "课前线下调研，根据企业情况进行模块化配置与定制，输出系统化培训方案",
    "global_support_customer_success_content_0_title_1": "时间灵活",
    "global_support_customer_success_content_0_follow_1": "线上线下相结合，根据客户时间定制培训方案",
    "global_support_customer_success_content_0_title_2": "1V1深度<br>培训",
    "global_support_customer_success_content_0_follow_2": "1v1培训，深度培训跟进辅导3-6个月，一年内持续服务，以达到项目所需建模能力",
    "global_support_customer_success_content_0_title_3": "双认证方向",
    "global_support_customer_success_content_0_follow_3": "认证可选建模和视效两个方向，更好的匹配客户业务需求",
    "global_support_customer_success_content_0_title_4": "集训课程",
    "global_support_customer_success_content_0_follow_4": "不定期举行线下或线上的集训课程",
    "global_support_customer_success_content_0_title_5": "多种品类",
    "global_support_customer_success_content_0_follow_5": "课程覆盖服饰箱包各大品类，根据产品提供匹配的课程",
    "global_support_customer_success_title_1": "3D能力成长路径",
    "global_support_customer_success_content_1_title_0": "建模师",
    "global_support_customer_success_content_1_subtitle_0": "3D能力定级培养",
    "global_support_customer_success_content_1_follow_0": "建模师能力定级分为初、中、高级，并有建模和视效两个方向，充分匹配不同的学习阶段、应用场景和业务需求",
    "global_support_customer_success_content_1_title_1": "版师",
    "global_support_customer_success_content_1_subtitle_1": "3D能力成长方案",
    "global_support_customer_success_content_1_follow_1": "当版师角色进行3D业务应用时，针对其需要具备的3D能力进行学习路径规划",
    "global_support_customer_success_content_1_title_2": "设计师",
    "global_support_customer_success_content_1_subtitle_2": "3D能力成长方案",
    "global_support_customer_success_content_1_follow_2": "当设计师角色进行3D业务应用时，针对其需要具备的3D能力进行学习路径规划",
    "global_support_customer_success_content_1_title_3": "考核标准",
    "global_support_customer_success_content_1_subtitle_3": "完善的培训考核标准",
    "global_support_customer_success_content_1_follow_3": "针对不同级别和方向的不同角色，制定完善的考核方案和标准，对团队3D能力充分进行准确评估",
    "global_support_customer_success_title_2": "企业内部带教赋能",
    "global_support_customer_success_content_2_title_0": "人才培养",
    "global_support_customer_success_content_2_follow_0": "为企业培养具备3D培训和支持能力的专家，推动企业内部形成可持续的3D应用基础",
    "global_support_customer_success_content_2_title_1": "体系搭建",
    "global_support_customer_success_content_2_follow_1": "辅导企业制定和搭建内部3D能力考核体系，帮助企业提升3D应用的内部管理能力",
    "global_support_customer_success_title_3": "场景应用结合",
    "global_support_customer_success_content_3_title_0": "资产沉淀",
    "global_support_customer_success_content_3_follow_0": "课程匹配企业3D资产沉淀需求，为企业建立自有3D资产库做好准备",
    "global_support_customer_success_content_3_title_1": "数字研发",
    "global_support_customer_success_content_3_follow_1": "课程匹配企业业务应用，赋能精准开发、3D评审，为3D数字化研发在企业的深度应用打好基础",
    "global_support_customer_success_content_3_title_2": "视效宣传",
    "global_support_customer_success_content_3_follow_2": "课程考虑企业视效宣传需求，包括平面海报、动态走秀等视效输出，可应用于电商上新、营销推广等场景",
    "global_support_contentservices_swipper_title": "Digital Product Creation Service",
    "global_support_contentservices_swipper_subtitle": "内容服务",
    "global_support_consultationservice_swipper_title": "Consulting Service",
    "global_support_consultationservice_swipper_subtitle": "咨询服务",
    "global_support_contentservices_info_title_0": "款式建模",
    "global_support_contentservices_info_subtitle_0": "3D Modeling",
    "global_support_contentservices_info_text_00": "工程文件",
    "global_support_contentservices_info_text_01": "实时模型",
    "global_support_contentservices_info_title_1": "款式渲染",
    "global_support_contentservices_info_subtitle_1": "3D Rendering",
    "global_support_contentservices_info_text_10": "单款 & 单款360°",
    "global_support_contentservices_info_text_11": "套装 & 套装360°",
    "global_support_contentservices_info_title_2": "款式动画",
    "global_support_contentservices_info_subtitle_2": "3D Animation",
    "global_support_contentservices_info_text_20": "原地动画",
    "global_support_contentservices_info_text_21": "标准动画",
    "global_support_contentservices_info_text_22": "创意动画",
    "global_support_contentservices_info_text_23": "轻量化裸眼3D",
    "global_support_contentservices_info_title_3": "面料",
    "global_support_contentservices_info_subtitle_3": "Fabric",
    "global_support_contentservices_info_text_30": "面料展示",
    "global_support_contentservices_info_text_31": "面料渲染",
    "global_support_contentservices_info_text_32": "数字面料",
    "global_support_contentservices_info_title_4": "综合展示",
    "global_support_contentservices_info_subtitle_4": "Comprehensive Representation",
    "global_support_contentservices_info_text_40": "电子看板",
    "global_support_contentservices_info_text_41": "企业展厅",
    "global_support_contentservices_info_text_42": "VR展厅",
    "global_aboutus_introduction_swipper_title": "Welcome to Style3D",
    "global_aboutus_introduction_swipper_card_top_text_top": "凌迪科技Style3D是一家以“AI + 3D”技术为核心驱动力的科技企业，专注于提供数字资产创作、展示、协同的工具和解决方案，推动全球时尚行业的数字化转型和创新发展。",
    "global_aboutus_introduction_swipper_card_top_text_bottom": "打造数字引擎·驱动时尚未来",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des1": "客户第一",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des2": "敢为极致",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des3": "多元协作",
    "global_aboutus_introduction_swipper_card_top_text_bottom_des4": "共同成长",
    "global_aboutus_introduction_development_title": "发展历程",
    "global_aboutus_introduction_development_year_1": "2023",
    "global_aboutus_introduction_development_year_1_events_1": "发布Style3D AI产业模型，以“AIGC+3D”技术真正推动生产力的发展",
    "global_aboutus_introduction_development_year_2": "2022",
    "global_aboutus_introduction_development_year_2_events_1": "全国首批数字时尚领域国家标准发布，Style3D参与制定",
    "global_aboutus_introduction_development_year_2_events_2": "参与编著“十四五”教材《3D数字化服装设计》",
    "global_aboutus_introduction_development_year_2_events_3": "Style3D面料数字化系列硬件发布，实现软硬件一站式数字化闭环",
    "global_aboutus_introduction_development_year_3": "2021",
    "global_aboutus_introduction_development_year_3_events_1": "宣布设立“凌迪图形学奖学金”，聚焦图形学技术人才培养",
    "global_aboutus_introduction_development_year_3_events_2": "正式成立凌迪研究院，组建国际TOP级图形学研究团队",
    "global_aboutus_introduction_development_year_4": "2020",
    "global_aboutus_introduction_development_year_4_events_1": "Style3D作为服装数字化产业技术领军品牌之一，为疫情影响下的服装企业们，提供全套3D远程协同解决方案，成功打响数字化推款战“疫”",
    "global_aboutus_introduction_development_year_5": "2019",
    "global_aboutus_introduction_development_year_5_events_1": "国内首个服装3D建模设计软件Style3D Studio产品正式上线，亮相CHIC展",
    "global_aboutus_introduction_development_year_5_events_2": "",
    "global_aboutus_introduction_development_year_6": "2017",
    "global_aboutus_introduction_development_year_6_events_1": "完成自主柔性仿真引擎的自主研发",
    "global_aboutus_introduction_development_year_7": "2015",
    "global_aboutus_introduction_development_year_7_events_1": "凌迪Style3D公司正式成立",
    "global_aboutus_introduction_fivepoint_financing_title_1": "顺为资本",
    "global_aboutus_introduction_fivepoint_financing_title_2": "百度风投",
    "global_aboutus_introduction_fivepoint_financing_title_3": "元璟资本",
    "global_aboutus_introduction_fivepoint_financing_title_4": "高榕资本",
    "global_aboutus_introduction_fivepoint_financing_title_5": "联想创投",
    "global_aboutus_introduction_fivepoint_financing_title_6": "HILLHOUSE",
    "global_aboutus_introduction_fivepoint_title_1": "融资概况",
    "global_aboutus_introduction_fivepoint_title_2": "办公地点",
    "global_aboutus_introduction_fivepoint_title_5": "联系我们",
    "global_aboutus_introduction_fivepoint_colorcard_title": "加入我们",
    "global_aboutus_introduction_fivepoint_colorcard_title1": "Style3D奖学金计划",
    "global_aboutus_introduction_fivepoint_concat_us_location": "公司地址：浙江省杭州市西湖区蒋村街道双龙街<br>99号三深国际中心A座3楼",
    "global_aboutus_introduction_fivepoint_concat_us_phone": "官方热线：400-158-0699<br>商务合作：<a>sales{'@'}style3d.com</a><br>媒体合作：<a>pr{'@'}style3d.com</a>",
    "global_aboutus_introduction_fivepoint_office_location_title1": "总部",
    "global_aboutus_introduction_fivepoint_office_location_subtitle1": "中国 · 杭州",
    "global_aboutus_introduction_fivepoint_office_location_title2": "国内其他办公地：",
    "global_aboutus_introduction_fivepoint_office_location_subtitle2": "上海、广州<br>深圳、厦门<br>青岛、香港",
    "global_aboutus_introduction_fivepoint_office_location_title3": "海外办公地：",
    "global_aboutus_introduction_fivepoint_office_location_subtitle3": "法国：巴黎<br>英国：伦敦、曼彻斯特<br>意大利：米兰<br>比利时：布鲁塞尔",
    "global_aboutus_introduction_fivepoint_office_location_subtitle4": "德国：慕尼黑<br>希腊：雅典<br>越南：胡志明、河内",
    "global_aboutus_responsibility_swipper_title": "Corporate Social<br>Responsibility",
    "global_aboutus_responsibility_swipper_subtitle": "企业社会责任",
    "global_aboutus_responsibility_swipper_info_title": "科技创新 时尚向善",
    "global_aboutus_responsibility_swipper_info_subtitle": "",
    "global_aboutus_responsibility_greencard_title1": "Style3D以“科技创新，时尚向善”作为公司的社会责任理念，依托公司的产品和技术，实现商业价值和社会价值的一致性。",
    "global_aboutus_responsibility_greencard_title2": "“我相信未来的物理世界是数字化的。我们希望成为底层技术的提供者，和一些伟大的公司一样，来提升纺织服装行业的效率。通过计算机的核心算法，使个人消费者、企业和社会三者利益最大化，推动整个产业创新和发展。”",
    "global_aboutus_responsibility_greencard_title3": " — Style3D创始人兼首席执行官  刘郴",
    "global_aboutus_responsibility_iconcard_info_minititle": "我们的承诺：",
    "global_aboutus_responsibility_iconcard_info_title": "3D数字化研发 可持续时尚发展",
    "global_aboutus_responsibility_iconcard_info_subtitle": "Style3D以3D数字化赋能时尚行业数字化，通过高仿真的3D数字技术帮助时尚产业降本提效，进而减少样衣资源浪费，践行全球可持续发展战略。",
    "global_aboutus_responsibility_iconcardlist_title1": "环境可持续",
    "global_aboutus_responsibility_iconcardlist_subtitle1": "Style3D以数字形式进行时尚产品研发、交互及展示，以数字样衣替代实物样衣在产业链流转及展示，大幅减少研发业务流程中的物料浪费，共同建设环保、可持续的社会生态大环境。",
    "global_aboutus_responsibility_iconcardlist_title2": "行业可持续",
    "global_aboutus_responsibility_iconcardlist_subtitle2": "Style3D以技术创新赋能时尚产业链数字升级，提高研发效率，赋能产业良性发展，通过数字资产加强对服饰类产品原创设计的知识产权保护，打造健康、和谐、规范、有生命力的行业生态。",
    "global_aboutus_responsibility_iconcardlist_title3": "企业可持续",
    "global_aboutus_responsibility_iconcardlist_subtitle3": "Style3D数字研发模式助力企业研发降本提效，减少库存压力，提升抗风险能力，建立高效的快反供应链，推动企业可持续发展。",
    "global_aboutus_fellowship_swipper_title": "Graduate Fellowship Program",
    "global_aboutus_fellowship_swipper_subtitle": "奖学金计划",
    "global_aboutus_fellowship_project_info_title": "投资未来 持续成长",
    "global_aboutus_fellowship_project_info_card_content": "Style3D 坚信对大学人才的资助是未来持续成长和成功的关键。自2021年起，Style3D 研究生奖学金计划持续资助在计算机图形学领域具有研究兴趣并展示潜力的年轻研究学者。",
    "global_aboutus_fellowship_content_title_0": "往期申请时间",
    "global_aboutus_fellowship_content_enroll_end_time": "报名截止日期：",
    "global_aboutus_fellowship_content_title_1": "资助形式",
    "global_aboutus_fellowship_content_title_2": "资格标准",
    "global_aboutus_fellowship_content_title_3": "申请材料",
    "global_aboutus_fellowship_content_title_4": "评审流程",
    "global_aboutus_fellowship_content_bonus_0": "每位获奖者无条件获30,000人民币的现金奖励；共8到10人。",
    "global_aboutus_fellowship_content_bonus_1": "获奖者受邀参加CCF CAD&CG会议或CSIAM GDC会议组织的颁奖活动，与全球的研究人员进行研究分享。",
    "global_aboutus_fellowship_content_bonus_2": "获奖者可以前往凌迪科技Style3D 进行暑期实习，地点位于中国杭州或美国纽约。",
    "global_aboutus_fellowship_content_standards_0": "申请者应在中国（含香港、澳门、台湾）的大学或机构攻读研究生项目。",
    "global_aboutus_fellowship_content_standards_1": "申请者应主修计算机科学、计算机工程、软件工程、数据科学、多媒体或相关领域。",
    "global_aboutus_fellowship_content_standards_2": "申请人应参与图形学研究活动，包括但不限于：几何建模、渲染、动画和模拟、计算机辅助设计、VR/AR/MR、计算制造、可视化、人机交互、图像/视频处理、智能化图形内容生成。",
    "global_aboutus_fellowship_content_standards_3": "申请人在接下来的六个月内无毕业计划。",
    "global_aboutus_fellowship_content_standards_4": "申请人应在申请前获得导师的批准。",
    "global_aboutus_fellowship_content_standards_5": "凌迪科技内部员工及其亲属不能申请。",
    "global_aboutus_fellowship_content_document_0": "申请人须提交奖学金申请表（点击页面上方“下载申请表”）。",
    "global_aboutus_fellowship_content_document_1": "申请人须提交个人简历/履历（中英文均可）。",
    "global_aboutus_fellowship_content_document_2": "申请人须提交研究摘要/提案-最多4页，包括参考文献。",
    "global_aboutus_fellowship_content_document_3": "申请人须提交1～3封推荐信，其中一封应来自导师。",
    "global_aboutus_fellowship_content_document_4": "所有申请材料须在截止日期前通过电子邮件提交至Fellowship@linctex.com",
    "global_aboutus_fellowship_content_procedure_0": "评审委员会由3至5名专家构成，分别来自学术界和凌迪科技。",
    "global_aboutus_fellowship_content_procedure_1": "评价标准主要为申请人的学术背景、研究质量以及与凌迪的相关性。",
    "global_aboutus_fellowship_content_procedure_2": "如有需要，委员会将在最终决定前组织答辩环节。",
    "global_aboutus_fellowship_content_procedure_3": "同一所大学或机构的获奖者原则上不超过两名。",
    "global_resource_center_case_swipper_title": "Customer Case",
    "global_resource_center_case_swipper_subtitle": "客户案例",
    "global_resource_center_case_card_title_0": "Style3D x 迪尚集团",
    "global_resource_center_case_card_des_0": "迪尚引入Style3D数字研发平台，将其融入自身的云设计中心，并结合柔性制造基地和智能消费应用…",
    "global_resource_center_case_card_title_1": "Style3D x 夏利达",
    "global_resource_center_case_card_des_1": "Style3D荣获“2022年度元宇宙领域产业化创新应用大赛优秀成果奖”｜信息化百人会",
    "global_resource_center_case_card_title_2": "Style3D x 安踏 x 百度 ",
    "global_resource_center_case_card_des_2": "三方携手打造虚拟时装秀，亮相SS23中国国际时装周",
    "global_resource_center_case_card_title_3": "Style3D x KASHION",
    "global_resource_center_case_card_des_3": "在多年的良好合作后，KASHION已成功运用Style3D数字化工具及链接其生态内多位合作伙伴…",
    "global_resource_center_news_swipper_title": "News Center",
    "global_resource_center_news_swipper_subtitle": "新闻中心",
    "global_resource_center_news_card_des_0": "持续领跑！Style3D入选腾讯、虎嗅分析报告，登榜36氪年度企业榜单",
    "global_resource_center_news_card_des_1": "Style3D荣获“2022年度元宇宙领域产业化创新应用大赛优秀成果奖”｜信息化百人会",
    "global_resource_center_partners_swipper_title": "Partners",
    "global_resource_center_partners_swipper_subtitle": "合作伙伴",
    "global_resource_center_partners_swipper_describe": "Style3D与全球业界领先者达成合作，携手共创3D行业未来",
    "global_resource_center_partners_title_0": "生态合作伙伴",
    "global_resource_center_partners_title_1": "渠道合作伙伴",
    "global_resource_center_partners_des_1": "业务遍及60个国家和地区，为全球客户提供及时、高效、专业的产品和服务",
    "global_resource_center_partners_des_1_mobile": "业务遍及60个国家和地区，为全球客户提供及时、高效、专业的产品和服务",
    "global_resource_center_apply_trial_submit_success": "试用申请已提交",
    "global_send_vcode_fail": "验证码发送失败，请稍后重试",
    "global_policy_file_privacy": "隐私政策",
    "global_policy_file_cookie": "Cookie政策",
    "global_policy_file_service": "服务条款",
    "global_solutions_manufacturer_arrows_progressbar_title_0": "打样寄样",
    "global_solutions_manufacturer_arrows_progressbar_title_1": "客审改款",
    "global_solutions_manufacturer_arrows_progressbar_title_2": "齐色制作",
    "global_solutions_manufacturer_arrows_progressbar_text_0": "传统: 7天 VS 3D:",
    "global_solutions_manufacturer_arrows_progressbar_bluetext_0": "10小时",
    "global_solutions_manufacturer_arrows_progressbar_text_1": "传统: 7天 VS 3D:",
    "global_solutions_manufacturer_arrows_progressbar_bluetext_1": "4小时",
    "global_solutions_manufacturer_arrows_progressbar_text_2": "传统: 5天 VS 3D:",
    "global_solutions_manufacturer_arrows_progressbar_bluetext_2": "2小时",
    "global_solutions_manufacturer_arrows_progressbar_faster": "提速",
    "global_solutions_manufacturer_arrows_progressbar_times": "倍",
    "global_solutions_brand_panoramagram_gsap_maxtext_brand": "品牌商",
    "global_solutions_brand_panoramagram_gsap_maxtext_xiaofei": "消费者",
    "global_solutions_brand_panoramagram_gsap_maxtext_maufacturertop": "成衣",
    "global_solutions_brand_panoramagram_gsap_maxtext_maufacturerbottom": "制造商",
    "global_solutions_brand_panoramagram_gsap_maxtext_fabrictop": "面料",
    "global_solutions_brand_panoramagram_gsap_maxtext_fabricbottom": "供应商",
    "global_solutions_brand_panoramagram_gsap_mintext_purchase": "选购采买",
    "global_solutions_brand_panoramagram_gsap_mintext_fabricsupply": "数字面料供给",
    "global_solutions_brand_panoramagram_gsap_mintext_clothsupply": "数字成衣供给",
    "global_solutions_edu_procedure_text_0": "人台数字化",
    "global_solutions_edu_procedure_text_1": "面料数字化",
    "global_solutions_edu_procedure_text_2": "数字化款式设计",
    "global_solutions_edu_procedure_text_3": "线上协同审款改款",
    "global_solutions_edu_procedure_text_4": "数字化智能制造",
    "global_solutions_edu_procedure_text_5": "数字化展示营销",
    "global_home_header_submenu_link_long_1_0": "品牌商解决方案",
    "global_home_header_submenu_link_long_1_1": "制造商解决方案",
    "global_home_header_submenu_link_long_1_2": "面辅料商解决方案",
    "global_home_header_submenu_link_long_1_3": "教育行业解决方案",
    "global_home_header_submenu_link_long_1_4": "虚拟时尚解决方案",
    "global_home_header_submenu_link_long_4_1": "企业社会责任",
    "global_dpc_content_services_text_0": "全方位综合展示服装和面料资产",
    "global_unit_text_1": "",
    "global_aboutus_fellowship_detail_text_0": "奖金",
    "global_aboutus_fellowship_detail_text_1": "现金",
    "global_aboutus_fellowship_detail_text_2": "人",
    "global_aboutus_fellowship_content_announce_time": "宣布获奖名单：",
    "global_aboutus_fellowship_history_title": "往期获奖名单",
    "global_aboutus_fellowship_winner_list_2023_0_name": "吴桐",
    "global_aboutus_fellowship_winner_list_2023_0_university": "中国科学院大学",
    "global_aboutus_fellowship_winner_list_2023_1_name": "张宇欣",
    "global_aboutus_fellowship_winner_list_2023_1_university": "中国科学院大学",
    "global_aboutus_fellowship_winner_list_2023_2_name": "李沫",
    "global_aboutus_fellowship_winner_list_2023_2_university": "中国科学技术大学",
    "global_aboutus_fellowship_winner_list_2023_3_name": "敖腾隆",
    "global_aboutus_fellowship_winner_list_2023_3_university": "北京大学",
    "global_aboutus_fellowship_winner_list_2023_4_name": "唐祥俊",
    "global_aboutus_fellowship_winner_list_2023_4_university": "浙江大学",
    "global_aboutus_fellowship_winner_list_2023_5_name": "沈越凡",
    "global_aboutus_fellowship_winner_list_2023_5_university": "浙江大学",
    "global_aboutus_fellowship_winner_list_2023_6_name": "曾令栀",
    "global_aboutus_fellowship_winner_list_2023_6_university": "清华大学",
    "global_aboutus_fellowship_winner_list_2023_7_name": "郑少锟",
    "global_aboutus_fellowship_winner_list_2023_7_university": "清华大学",
    "global_aboutus_fellowship_winner_list_2023_8_name": "李昊天",
    "global_aboutus_fellowship_winner_list_2023_8_university": "香港科技大学",
    "global_aboutus_fellowship_winner_list_2023_9_name": "邱陵腾",
    "global_aboutus_fellowship_winner_list_2023_9_university": "香港中文大学（深圳）",
    "global_aboutus_fellowship_winner_list_2022_0_name": "辛杭高",
    "global_aboutus_fellowship_winner_list_2022_0_university": "清华大学",
    "global_aboutus_fellowship_winner_list_2022_1_name": "倪星宇",
    "global_aboutus_fellowship_winner_list_2022_1_university": "北京大学",
    "global_aboutus_fellowship_winner_list_2022_2_name": "金旭统",
    "global_aboutus_fellowship_winner_list_2022_2_university": "北京大学",
    "global_aboutus_fellowship_winner_list_2022_3_name": "刘锋林",
    "global_aboutus_fellowship_winner_list_2022_3_university": "中国科学院计算技术研究所",
    "global_aboutus_fellowship_winner_list_2022_4_name": "肖楚烽",
    "global_aboutus_fellowship_winner_list_2022_4_university": "香港城市大学",
    "global_aboutus_fellowship_winner_list_2022_5_name": "袁林萍",
    "global_aboutus_fellowship_winner_list_2022_5_university": "香港科技大学",
    "global_aboutus_fellowship_winner_list_2022_6_name": "樊家辉",
    "global_aboutus_fellowship_winner_list_2022_6_university": "南京理工大学",
    "global_aboutus_fellowship_winner_list_2022_7_name": "黄步真",
    "global_aboutus_fellowship_winner_list_2022_7_university": "东南大学",
    "global_aboutus_fellowship_winner_list_2022_8_name": "刘嘉枫",
    "global_aboutus_fellowship_winner_list_2022_8_university": "浙江大学",
    "global_aboutus_fellowship_winner_list_2022_9_name": "董峻廷",
    "global_aboutus_fellowship_winner_list_2022_9_university": "浙江大学",
    "global_aboutus_fellowship_winner_list_2021_0_name": "黄家晖",
    "global_aboutus_fellowship_winner_list_2021_0_university": "清华大学",
    "global_aboutus_fellowship_winner_list_2021_1_name": "高端",
    "global_aboutus_fellowship_winner_list_2021_1_university": "清华大学",
    "global_aboutus_fellowship_winner_list_2021_2_name": "宿建平",
    "global_aboutus_fellowship_winner_list_2021_2_university": "中国科学与技术大学",
    "global_aboutus_fellowship_winner_list_2021_3_name": "杨开智",
    "global_aboutus_fellowship_winner_list_2021_3_university": "中国科学技术大学",
    "global_aboutus_fellowship_winner_list_2021_4_name": "张子豪",
    "global_aboutus_fellowship_winner_list_2021_4_university": "中国科学院计算技术研究所",
    "global_aboutus_fellowship_winner_list_2021_5_name": "徐奔",
    "global_aboutus_fellowship_winner_list_2021_5_university": "南开大学",
    "global_aboutus_fellowship_winner_list_2021_6_name": "赵航",
    "global_aboutus_fellowship_winner_list_2021_6_university": "国防科技大学",
    "global_aboutus_fellowship_winner_list_2021_7_name": "朱君秋",
    "global_aboutus_fellowship_winner_list_2021_7_university": "山东大学",
    "global_aboutus_fellowship_winner_list_2021_8_name": "邓紫坤",
    "global_aboutus_fellowship_winner_list_2021_8_university": "浙江大学",
    "global_aboutus_fellowship_winner_list_2021_9_name": "彭思达",
    "global_aboutus_fellowship_winner_list_2021_9_university": "浙江大学"
}
