import { ref, onBeforeMount, Ref, reactive } from 'vue';
import { ifCNLocale } from '~~/composables/useLocale.ts';

// onMounted
export function matchMobile() {
    return window.matchMedia('(max-width: 750px)').matches;
}

export function matchHighResolutionScreen() {
    return window.matchMedia('(min-width: 1440px)').matches;
}

export function getIsMobileRef(): Ref<boolean> {
    const isMobile = ref(false);

    onBeforeMount(() => {
        isMobile.value = matchMobile();
        console.log('isMobile.value', isMobile.value);
    });

    return isMobile;
}

export function getIsHighResolutionScreenRef(): Ref<boolean> {
    const isHighResolutionScreen = ref(false);

    onBeforeMount(() => {
        isHighResolutionScreen.value = matchHighResolutionScreen();
    });

    return isHighResolutionScreen;
}

export function getScreenMatcherRef() {
    const screenMatcher = reactive({
        isMobile: false,
        isHighResolutionScreen: false,
    });

    onBeforeMount(() => {
        screenMatcher.isMobile = matchMobile();
        screenMatcher.isHighResolutionScreen = matchHighResolutionScreen();
    });

    return screenMatcher;
}

export function getIsPcRef(): Ref<boolean> {
    const isPc = ref(false);

    onBeforeMount(() => {
        isPc.value = matchHighResolutionScreen();
    });

    return isPc;
}

export function getIsPcCnRef(): Ref<boolean> {
    const isPcCN = ref(false);

    onBeforeMount(() => {
        const isMobile = matchMobile();
        const isCn = ifCNLocale();
        isPcCN.value = !isMobile && isCn;
        console.log('onBeforeMount', isMobile, isCn, isPcCN.value);
    });

    return isPcCN;
}
