import plugin_vue3_d6ePiW4BHx from "/home/jenkins/workspace/Prod_style3d_Front_homesite/node_modules/.pnpm/@pinia+nuxt@0.5.1_zpcsyvub4cfuoabwb7qxsqocpy/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/.nuxt/components.plugin.mjs";
import unhead_zzyCxJsSqq from "/home/jenkins/workspace/Prod_style3d_Front_homesite/node_modules/.pnpm/nuxt@3.3.1_ebj75wg6xqyj2ck5bpxuqorgby/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import vueuse_head_polyfill_0qtw9FsQW6 from "/home/jenkins/workspace/Prod_style3d_Front_homesite/node_modules/.pnpm/nuxt@3.3.1_ebj75wg6xqyj2ck5bpxuqorgby/node_modules/nuxt/dist/head/runtime/plugins/vueuse-head-polyfill.js";
import router_aWrvNjjMRM from "/home/jenkins/workspace/Prod_style3d_Front_homesite/node_modules/.pnpm/nuxt@3.3.1_ebj75wg6xqyj2ck5bpxuqorgby/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_8syU4aQmj0 from "/home/jenkins/workspace/Prod_style3d_Front_homesite/node_modules/.pnpm/nuxt@3.3.1_ebj75wg6xqyj2ck5bpxuqorgby/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_FRmGFsEaPh from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/.nuxt/plugin.mjs";
import chunk_reload_client_krJ9nraWhw from "/home/jenkins/workspace/Prod_style3d_Front_homesite/node_modules/.pnpm/nuxt@3.3.1_ebj75wg6xqyj2ck5bpxuqorgby/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_sawBoXtlGD from "/home/jenkins/workspace/Prod_style3d_Front_homesite/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_mzpj6z3jklmjtyuik5osoydb3u/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import Lenis_client_OJB9LjCcyW from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/Lenis.client.ts";
import clickOutside_ZB3Eg0wOVk from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/clickOutside.ts";
import i18n_VfGcjrvSkj from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/i18n.ts";
import mitt_client_7uatExallr from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/mitt.client.ts";
import route_cvS2qIkLzw from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/route.ts";
import virtualScroller_Tp1Q0WbVRA from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/virtualScroller.ts";
import vueLazyload_Q72Q0vybs6 from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/vueLazyload.ts";
import xhr_5LCDglL0EQ from "/home/jenkins/workspace/Prod_style3d_Front_homesite/apps/main/plugins/xhr.ts";
export default [
  plugin_vue3_d6ePiW4BHx,
  components_plugin_KR1HBZs4kY,
  unhead_zzyCxJsSqq,
  vueuse_head_polyfill_0qtw9FsQW6,
  router_aWrvNjjMRM,
  prefetch_client_8syU4aQmj0,
  plugin_FRmGFsEaPh,
  chunk_reload_client_krJ9nraWhw,
  plugin_sawBoXtlGD,
  Lenis_client_OJB9LjCcyW,
  clickOutside_ZB3Eg0wOVk,
  i18n_VfGcjrvSkj,
  mitt_client_7uatExallr,
  route_cvS2qIkLzw,
  virtualScroller_Tp1Q0WbVRA,
  vueLazyload_Q72Q0vybs6,
  xhr_5LCDglL0EQ
]